import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import SectionsContainer from '../../../components/SectionsContainer'

const BreakoutSessions = () => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFaq(
        filter: { type: { eq: "Reunion" } }
        sort: { fields: order }
      ) {
        edges {
          node {
            id
            title
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    }
  `)

  const FAQs = data.allContentfulFaq.edges

  return (
    <SectionsContainer>
      <section className="padded-section wrap body-content">
        <h3 className="headline--reunion center-align">Policies</h3>
        <ol>
          <li>Cancellation Fee: $30 USD | $40 CAD fee through Saturday, August 31, 2024, 11:59 pm MDT.</li>
          <li>No refunds on cancellations beginning Sunday, September 1, 2024.</li>
        </ol>
      </section>
      <section className="padded-section wrap">
        <h3 className="headline--reunion center-align">FAQ</h3>
        {FAQs.map(({ node: QAndA }) => (
          <div key={QAndA.id}>
            <h4 className="section-label">{QAndA.title}</h4>
            <div
              className="body-content"
              dangerouslySetInnerHTML={{
                __html: QAndA.body.childMarkdownRemark.html,
              }}
            />
          </div>
        ))}
      </section>
    </SectionsContainer>
  )
}

export default BreakoutSessions
